<template>
  <octo-table
    show-filters
    enable-export
    enable-export-email
    :action="endpoints.DATATABLES.leads"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="filters = {}"
  >
    <template v-slot:name="data">
      <div class="text-uppercase text-truncate">
        {{ data.row.surname }} {{ data.row.name }}
      </div>
      <div class="small" v-if="data.row.operator">
        <span class="text-uppercase">{{$t('common.operator_prefix')}}</span>:
        <label-theme-component class="text-uppercase">{{ data.row.operator }}</label-theme-component>
      </div>
    </template>

    <template v-slot:provider="data">
      <div class="small text-uppercase">
        {{ data.row.provider }}
      </div>
    </template>

    <template v-slot:date_in="data">
      <div class="small">
        {{ data.row.date_in | date }}
      </div>
    </template>

    <template v-slot:date_out="data">
      <div class="small" v-if="data.row.date_out">
        {{data.row.date_out | date}}
      </div>
    </template>

    <template v-slot:phone="data">
      <div class="text-truncate small">
        {{ data.row.phones }}
      </div>
    </template>

    <template v-slot:email="data">
      <div class="text-truncate small">
        {{ data.row.emails }}
      </div>
    </template>

    <template v-slot:campaigns="data">
      <div
        v-if="data.row.campaigns"
        v-for="campaign in data.row.campaigns.split(', ')"
        class="small text-uppercase">
        {{ campaign }}
      </div>
    </template>

    <template v-slot:status="data">
      <badge :type="data.row.status | leadStatusColor" class="text-uppercase">
        {{ $t('callcenter.status.' + data.row.status) }}
      </badge>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <el-tooltip :content="$t('common.info')"
                    :open-delay="300"
                    placement="top">
          <base-button
            class="mx-1"
            size="sm"
            link
            icon
            @click="$router.push({name: 'leads.archive.show', params: {id : data.row.id }})"
          >
            <octo-icon icon="right-arrow"/>
          </base-button>
        </el-tooltip>
      </div>
    </template>

    <template slot="custom-filters">
      <div class="col-12 col-md-6">
        <base-input :label="$t('datatable.operators')">
          <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.operatorIds"
          >
            <el-option
                v-for="option in operatorsOptions"
                class="select-default text-uppercase"
                :value="option.value"
                :label="option.label"
                :key="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-6">
        <base-input :label="$t('datatable.campaign')">
          <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.campaignIds"
          >
            <el-option
                v-for="option in campaignsOptions"
                class="select-default text-uppercase"
                :value="option.value"
                :label="option.label"
                :key="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('fields.date_in')">
          <el-date-picker
            v-model="filters.dateInRange"
            type="daterange"
            align="right"
            unlink-panels
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="$t('fields.date_start')"
            :end-placeholder="$t('fields.date_end')"
            :picker-options="pickerOptions"
          />
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('fields.date_out')">
          <el-date-picker
            v-model="filters.dateOutRange"
            type="daterange"
            align="right"
            unlink-panels
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="$t('fields.date_start')"
            :end-placeholder="$t('fields.date_end')"
            :picker-options="pickerOptions"
          />
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('datatable.status')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('fields.status')"
            v-model="filters.status"
          >
            <el-option
              v-for="option in statusOptions"
              class="select-default text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import LeadStatuses from "../../CallCenter/resources/leadStatuses";
import {Select, Option, DatePicker} from "element-ui";
import {mapGetters} from "vuex";
import {permissionService} from "@/util/permission-service";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "LeadArchiveDatatable",
  components: {
    LabelThemeComponent,
    Badge,
    OctoIcon,
    OctoTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      name: "LeadArchiveDatatable",
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'date_in', label: 'date_in', align: 'center', slot: true, width: 130,fixed:true},
        {prop: 'name', label: 'name', slot: true, align: 'left',width: 250,fixed:true},
        {prop: 'status', label: 'status', width: 150, slot: true, align: 'center'},
        {prop: 'date_out', label: 'date_out', slot: true, align: 'center', width: 150},
        {prop: 'provider', label: 'provider', slot: true, width: 200, align: 'center'},
        {prop: 'campaigns', label: 'campaigns', align: 'center', width: 200, slot: true},
        {prop: 'phone', label: 'phone', align: 'center', slot: true, width: 200,},
        {prop: 'email', label: 'email', align: 'center', slot: true, width: 250,},
        {prop: 'actions', label: 'actions', slot: true, align: 'center'},
      ],
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
      campaigns: 'common/campaigns',
      users: 'common/users',
    }),

    statusOptions: function () {
      return this.$_.map(LeadStatuses, item => {
        return {
          value: item.value,
          label: this.$t('callcenter.status.' + item.value)
        };
      });
    },

    campaignsOptions() {
      return this.$_.map(this.campaigns, campaign => {
        return {
          label: campaign.name,
          value: campaign.id
        }
      });
    },

    operatorsOptions() {
      const operators = this.$_.filter(this.users, user => {
        return this.$_.find(user.roles, role => {
          return role.name === permissionService.CALL_CENTER_OPERATOR_ROLE;
        })
      });

      return this.$_.map(operators, user => {
        return {
          label: (user?.lastname + ' ' + user?.firstname) || user.username,
          value: user.id
        }
      });
    },

    pickerOptions() {
      return {firstDayOfWeek: 1};
    }
  }
}
</script>

<style scoped>

</style>
