<template>
  <div class="row">
    <div class="col-md-12">
      <lead-archive-datatable/>
    </div>
  </div>
</template>

<script>
import LeadArchiveDatatable from "./datatables/LeadArchiveDatatable";
export default {
  name: "LeadArchiveIndexPage",
  components: {LeadArchiveDatatable}
}
</script>

<style scoped>

</style>
